import { About, Skills, MyProjects } from "../index";

export const Home = () => {
  return (
    <>
      <About />
      <Skills />
      <MyProjects />
    </>
  );
};
