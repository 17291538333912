import app1Login from "../assets/Images/taskAppLogin.sprite.png";
import app1Tasks from "../assets/Images/taskAppTasks.sprite.png";
import app1Edit from "../assets/Images/taskAppEdit.sprite.png";

export const Images = [
  {
    image: app1Login,
  },
  {
    image: app1Tasks,
  },
  {
    image: app1Edit,
  },
];
