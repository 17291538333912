import { SkillsList } from "../../components/SkillsList";
import "./Skills.css";

export const Skills = () => {
  return (
    <div id="skills">
      <SkillsList />
    </div>
  );
};
